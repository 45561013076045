
h1 {
  font-size: 30px;  
}

h2 {
  font-size: 25px;  
}

h3 {
  font-size: 20px;  
}

.animate__animated.animate__fadeIn {
  --animate-duration: 4s;
}

.elemento_da_destra {
  animation: slideInRight; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
}

.frase {
  background-color: rgb(2, 91, 107);
  color:rgb(228, 241, 254);
  font-weight: bold;
  margin-top: 10px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: large;
}

.frase-cliccabile {
  cursor: pointer;
}

.frase-cliccabile:hover {
  background-color: rgb(1, 133, 157);
}

.frase1 {
  margin-left: 10px;
}
.frase2 {
  margin-left: 25px;
}
.frase3 {
  margin-left: 40px;
}
.frase4 {
  margin-left: 55px;
}

.dettaglio {
  background-color: #F0F0F0;
  color: #000000;
  padding: 10px;
  font-size: medium;
  line-height:150%;
  border-bottom: 3px solid rgb(2, 91, 107);
  border-left: 3px solid rgb(2, 91, 107);
  border-right: 3px solid rgb(2, 91, 107);
}

.fondo_pagina {
  background-color: darkgray;
}

.contenitore-intera-pagina {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.contenitore-superiore {
  background-color: #ffffff;  
  box-sizing: border-box; /* Considera il padding nei calcoli di dimensioni */
  padding-left:20px;  
}

.contenitore-centrale {
  overflow-y: auto; /* Aggiungi la scrollbar solo se il contenuto supera l'altezza */
  overflow-x: hidden;
  background-color: #E0E0E0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box; /* Considera il padding nei calcoli di dimensioni */
  position:relative; /* serve per poter poi mettere al centro la scritta dentro, con absolute */
}

.contenitore-menu {  
  width: 100%;   
  display: flex;
  flex-wrap: wrap
}

@media (min-width: 700px) {
  .contenitore-superiore {
    height: 140px;
  }
  .contenitore-menu {
    height: 140px;
  }
  .testo-centrato-home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente per migliorare la leggibilità */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  @media (min-height: 520px) {
    .contenitore-centrale {
      height: calc(100vh - 140px); /* Altezza totale - altezza della div del titolo */
    }
  }
}

@media (max-width: 699px) {
  .contenitore-superiore {
    height: 180px;
  }
  .contenitore-menu {
    height: 180px;
  }
  .testo-centrato-home {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0px);
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente per migliorare la leggibilità */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  @media (min-height: 520px) {
    .contenitore-centrale {
      height: calc(100vh - 180px); /* Altezza totale - altezza della div del titolo */
    }
  }
}

.contenitore-inferiore-fisso-in-fondo {
  position: absolute;
  bottom: 0px;
}

.contenitore-inferiore {
  height: 100px;
  left: 0px; /* Margine a sinistra */
  right: 0px; /* Margine a destra */
  background-color: #505050;
  color: #E0E0E0;
  padding: 20px;
  box-sizing: border-box; /* Considera il padding nei calcoli di dimensioni */
  text-align: center;
}

.voce-menu-inferiore {
  cursor: pointer;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
}

.voce-menu-inferiore:hover {
  color: #ffffff;
}

.bottone1 {
  cursor: pointer;
  white-space: nowrap;
  background-color: #4f8caf;
  color: #FFFFFF;
  font-weight: bold;
  font-size: large;
  text-align: center;
  margin-top: 20px;
  padding: 5;  
}

.bottone1:hover {
  background-color: #62a2c8;
}